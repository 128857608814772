
import {Options, Vue} from 'vue-class-component';
import {NavBar} from '@/common/wap';
import {Dialog, Toast, Cell, Field, Popup, Button, Picker, Icon} from 'vant';
import {App, Format} from '@/services';
import {GetUser} from '@/model/tokenGetUser';
import {userCard} from '@/services/server/wap/bank';
import {ICard, ICardListResult, IBankCard, IMessage} from '@/model/bank';
import {phoneVerification} from '@/services/server/wap/phone-verification';
import TelInput from '@/common/wap/telInput/index.vue';

@Options({
  components: {
    NavBar,
    vanDialog: Dialog.Component,
    vanCell: Cell,
    vanField: Field,
    vanPopup: Popup,
    vanButton: Button,
    vanPicker: Picker,
    vanIcon: Icon,
    TelInput
  },
  methods: {
    checkInputs() {
      const [acc, pwd] = this.config.dollarSign !== '₫' ? [3, 4] : [2, 3];
      if (!/\d{8,19}/.test(this.inputList[acc].input)) {
        this.inputList[acc].errorMsg = 'ui_hint_acc_length8';
        return false;
      }
      if (!this.userInfo?.phoneNumber.verification && !this.phoneNumber) {
        this.info.phoneNumberMsg = 'ui_phone_not_entered';
        return false;
      }
      // if (!this.info.phoneCode) {
      //   this.info.phoneCodeMsg = 'tip_code_blank';
      //   return false;
      // }
      return true;
    },
    async onSubmit() {
      // if (!this.checkInputs()) return;
      // this.loading = true
      // if (this.userInfo?.phoneNumber.verification) {
      /* eslint-disable-next-line */
      await this.addCard({phone_number_code: this.info.phoneCode});
      // } else {
      //   const data = await this.bindSMS();
      //   if (!(data instanceof Error)) {
      //     /* eslint-disable-next-line */
      //     await this.addCard({phone_number_code: this.info.phoneCode});
      //   }
      // }
    },
    beforeCheckSms() {
      // console.log(this.$data.$_authMode, this.$_phoneNumber, this.)
      if (!this.userInfo.phoneNumber.verification && !this.phoneNumber) {
        this.phoneNumberMsg = 'ui_phone_not_entered';
        return false;
      } else {
        this.checkSMS();
      }
    },

    async checkSMS() {
      if (this.userInfo?.phoneNumber.verification) {
        this.showWaitTime();
        //已認證
        const data = await phoneVerification.read<IMessage>({});
        console.log('checkSMS data: ', data);
        if (data instanceof Error) {
          Toast({
            type: 'fail',
            message: data.message || 'fail',
            teleport: 'body'
          });
        } else {
          Toast({
            type: 'success',
            message: data.message || 'success',
            teleport: 'body'
          });
        }
        return data;
      } else {
        //未認證
        const data = await this.addSMS();
        return data;
      }
    },
    async addSMS(phone: string) {
      !phone && (phone = this.phoneNumber);
      this.showWaitTime();
      return await phoneVerification
        .created({
          /* eslint-disable-next-line */
          phone_number: phone
        })
        .then((response: any) => {
          console.log('response: ', response);
          // this.$toast.success(data.message)
          Toast({
            type: 'success',
            message: response?.message || 'success',
            teleport: 'body'
          });
          this.changePhoneStatus(3);
        })
        .catch(error => {
          // this.$toast.fail(error.message)
          Toast({
            type: 'fail',
            message: error.message || 'fail',
            teleport: 'body'
          });
          this.info.verPhoneWaitTime = this.info.phoneErrorWaitTime;
          return error;
        });
    },

    showWaitTime() {
      this.info.verPhoneWaitTime = this.info.phoneWaitTime;
      this.info.smsInterval = setInterval(() => {
        this.info.verPhoneWaitTime = +this.info.verPhoneWaitTime - 1;
        if (+this.info.verPhoneWaitTime === 0) {
          clearInterval(this.info.smsInterval);
        }
      }, 1000);
    }
  },
  computed: {
    verSms() {
      return this.info.phoneDisabled;
    },
    phoneNumber() {
      return this.$refs.tel ? this.$refs.tel.info.phoneObject.number.e164 : '';
    },
    validated() {
      const [acc, pwd] = this.config.dollarSign !== '₫' ? [3, 4] : [2, 3];

      if (this.control.bankId && this.control.bankCode && this.inputList[acc].input) {
        if (this.configdollarSign === '' && this.inputList[0].input) {
          return true;
        } else if (
          this.config.dollarSign !== '' &&
          this.inputList[0].input &&
          this.inputList[1].input
        ) {
          return true;
        }
        return false;
      }
      return false;
    }
  }
})
export default class AddBankCard extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥'
  };
  userInfo = App.getUserinfo();
  cardList: ICard[] = [];
  control: {
    showBanks: boolean;
    columns: IBankCard[];
    bankId: string;
    bankCode: string;
  } = {
    showBanks: false,
    columns: [],
    bankId: '', // 銀行 ID
    bankCode: ''
  };
  info = {
    phoneNumberMsg: '',
    phoneDisabled: true,
    phoneWaitTime: 300,
    phoneErrorWaitTime: 15,
    verPhoneWaitTime: 0,
    smsInterval: '',
    phoneCode: '',
    phone: '',
    phoneCodeMsg: ''
  };
  inputList = [
    ...(this.config.dollarSign !== '₫'
      ? [
          {
            text: 'ui_first_name',
            type: 'text',
            placeholder: 'ui_first_name',
            input: '',
            errorMsg: ''
          },
          {
            text: 'ui_last_name',
            type: 'text',
            placeholder: 'ui_last_name',
            input: '',
            errorMsg: ''
          }
        ]
      : [
          {
            text: 'ui_open_acc_name_colon',
            type: 'text',
            placeholder: 'ui_hint_real_name',
            input: '',
            errorMsg: ''
          }
        ]),
    {
      text: 'ui_open_acc_bank_colon',
      type: 'text',
      placeholder: 'ui_select',
      input: '',
      errorMsg: ''
    },
    {
      text: 'ui_bank_acc_colon',
      type: 'text',
      placeholder: 'ui_hint_acc_length8',
      input: '',
      errorMsg: ''
    },
    {
      text: 'ui_bank_pwd_colon',
      type: 'password',
      placeholder: 'ui_hint_acc_pwd',
      input: '',
      errorMsg: ''
    }
  ];

  async mounted() {
    if (!this.userInfo) {
      await GetUser.action();
      this.userInfo = App.getUserinfo();
    }
    const [res1, res2] = await Promise.all([
      userCard.info<ICardListResult>(),
      userCard.infoSettings({name: ['user_binding_card_time']})
    ]);
    console.log('res1, res2: ', res1, res2);
    if (!(res1 instanceof Error)) {
      const list = res1.list.map(v => {
        v.text = `${v.bankName}`;
        v.dom = `<span class="icon bank-img bank__icon-${v.bankCode}"></span><span>${v.bankName}</span>`;
        return v;
      });
      this.control.columns = [...this.control.columns, ...list];
    }
  }

  upperCase = Format.upperCase;

  onConfirm(bank: {text: string; bankId: string; bankCode: string}) {
    console.log('debug: onConfirm -> bank', bank);
    const idx = this.inputList.findIndex(i => i.text === 'ui_open_acc_bank_colon');
    this.inputList[idx].input = bank.text;
    this.control.bankId = bank.bankId;
    this.control.bankCode = bank.bankCode;
    this.control.showBanks = false;
  }

  async inputClick(boolean: boolean) {
    this.control.showBanks = boolean;
  }

  clearError() {
    this.inputList.forEach(v => (v.errorMsg = ''));
  }

  async addCard() {
    console.log(this.config, 'this.config');
    const [acc, pwd] = this.config.dollarSign !== '₫' ? [3, 4] : [2, 3];
    const para = {
      /* eslint-disable-next-line */
      bank_id: this.control.bankId, // 銀行 ID
      /* eslint-disable-next-line */
      bank_code: this.control.bankCode, // 銀行代碼
      province: '', // 省份 ID
      city: '', // 城市 ID
      branch: '', // 開戶支行
      number: this.inputList[acc].input, // 銀行帳號
      /* eslint-disable-next-line */
      confirm_number: this.inputList[acc].input
    };
    let params = {};

    if (this.config.dollarSign === '₫') {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params = {...params, name: this.inputList[0].input};
    } else {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params = {...params, first_name: this.inputList[0].input, last_name: this.inputList[1].input};
    }
    params = {...para, ...params};
    console.log({params});
    const data = await this.bindCard(params);
    console.log(`debug: onSubmit -> data`, data);
    if (data instanceof Error) {
      Toast.fail(data.message || 'fail');
      return false;
    } else {
      Toast({
        message: 'success',
        teleport: 'body'
      });
      this.inputList.forEach(v => (v.input = ''));
      setTimeout(() => {
        this.$router.back();
      }, 1000);
    }
    return data;
  }

  async bindSMS() {
    // let number = phoneUtil.parseAndKeepRawInput(this.$data.$_phone, this.$data.$_countryCode)
    const data = await phoneVerification.updated({
      // phone_number: phoneUtil.format(number, PNF.E164),
      code: this.info.phoneCode
    });
    if (data instanceof Error) {
      Toast({
        type: 'fail',
        message:
          this.$t('ui_verification_code_error') + '\r\n' + this.$t('ui_change_failed') || 'fail',
        teleport: 'body'
      });
      // console.log('data.message', data.message)
      // let message = this._$t('ui_verification_code_error') + '\r\n' + this._$t('ui_change_failed')
      // this.$toast.fail(message || 'fail')
      return false;
    } else {
      Toast({
        type: 'fail',
        message:
          this.$t('ui_verification_code_is_correct') + '\r\n' + this.$t('ui_change_successful') ||
          'fail',
        teleport: 'body'
      });
      //修改狀態
      await GetUser.action();
      this.userInfo = App.getUserinfo();
    }
    return data;
  }

  async bindCard(params: object) {
    const data = await userCard.created(params);
    return data;
  }
}
